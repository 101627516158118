/*** VARIABLES ***/
/*** MIXINS ***/
/*** FUNCTION ***/
/*** ANIMATIONS ***/
.animation-spinning, .header-content--active .header__spinner {
  animation: spin-zoom 15s 0.75s infinite linear; }

.animation-float-00, .submenu__float-image-wrapper-00 {
  animation: float-00 19s 0s infinite ease-in-out; }

.animation-float-01, .submenu__float-image-wrapper-01 {
  animation: float-01 15s 0s infinite ease-in-out; }

.animation-float-02, .submenu__float-image-wrapper-02 {
  animation: float-02 12s 0s infinite ease; }

@keyframes spin-zoom {
  0% {
    transform: rotate(0); }
  12% {
    transform: rotate(85deg) scale(1); }
  25% {
    transform: rotate(92.5deg) scale(1.075); }
  37% {
    transform: rotate(100deg) scale(1); }
  62% {
    transform: rotate(265deg) scale(1); }
  75% {
    transform: rotate(272.5deg) scale(1.075); }
  87% {
    transform: rotate(280deg) scale(1); }
  100% {
    transform: rotate(360deg) scale(1); } }

@keyframes float-00 {
  0% {
    transform: translateY(101vh); }
  5% {
    transform: translateY(101vh); }
  85% {
    transform: translateY(-66vh); }
  100% {
    transform: translateY(-66vh); } }

@keyframes float-01 {
  0% {
    transform: translateY(101vh); }
  75% {
    transform: translateY(-66vh); }
  100% {
    transform: translateY(-66vh); } }

@keyframes float-02 {
  0% {
    transform: translateY(101vh); }
  12% {
    transform: translateY(101vh); }
  95% {
    transform: translateY(-66vh); }
  100% {
    transform: translateY(-66vh); } }

.position-marker-50::after {
  content: '';
  position: fixed;
  width: 20px;
  height: 1px;
  background: #fff;
  left: 0;
  top: calc(50% - 1px); }

.align-horizontal-left {
  display: flex;
  flex-flow: row;
  justify-content: flex-start; }

.align-horizontal-center {
  display: flex;
  flex-flow: row;
  justify-content: center; }

.align-horizontal-right {
  display: flex;
  flex-flow: row;
  justify-content: flex-end; }

.center-element, .main-container {
  margin: 0 auto; }

.z-index-00, .submenu__float-background {
  z-index: 0; }

.z-index-01, .gradient-top::before, .brand-preview::before, .gradient-bottom::after, .brand-preview::after, .brand-preview__headline, .submenu__viewer-wrapper, .header__image-wrapper-01 .header__image {
  z-index: 50; }

.z-index-02, .submenu__list, .header-content::before, .header-content::after, .header__main-headline {
  z-index: 100; }

.z-index-03, .button-close-overlay {
  z-index: 150; }

.z-index-04, .sidebar, .progress-bar {
  z-index: 200; }

.z-index-05, .submenu, .menu {
  z-index: 250; }

.gradient-vertical, .menu {
  background: -moz-linear-gradient(top, black 0%, black 50%, rgba(0, 0, 0, 0.8) 66%, rgba(0, 0, 0, 0.7) 75%, rgba(0, 0, 0, 0.5) 85%, rgba(0, 0, 0, 0.33) 90%, rgba(0, 0, 0, 0.15) 95%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, black 0%, black 50%, rgba(0, 0, 0, 0.8) 66%, rgba(0, 0, 0, 0.7) 75%, rgba(0, 0, 0, 0.5) 85%, rgba(0, 0, 0, 0.33) 90%, rgba(0, 0, 0, 0.15) 95%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, black 0%, black 50%, rgba(0, 0, 0, 0.8) 66%, rgba(0, 0, 0, 0.7) 75%, rgba(0, 0, 0, 0.5) 85%, rgba(0, 0, 0, 0.33) 90%, rgba(0, 0, 0, 0.15) 95%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }

.text-fill, h1, h2 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff;
  color: transparent;
  transition: all 0.4s; }
  .text-fill:hover, h1:hover, h2:hover {
    color: #ffffff; }

.stop-scrolling {
  height: 100%;
  overflow: hidden; }

.gradient-top::before, .brand-preview::before {
  content: '';
  position: absolute;
  top: -5%;
  left: 0;
  width: 100%;
  height: 10%;
  background: -moz-linear-gradient(top, black 0%, black 60%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, black 0%, black 60%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, black 0%, black 60%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }

.gradient-bottom::after, .brand-preview::after {
  content: '';
  position: absolute;
  bottom: -6%;
  left: 0;
  width: 100%;
  height: 10%;
  background: -moz-linear-gradient(bottom, black 0%, black 66%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(bottom, black 0%, black 66%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top, black 0%, black 66%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }

* {
  box-sizing: border-box; }

html {
  margin: 0;
  padding: 0;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; }

body {
  margin: 0;
  padding: 0; }

a {
  color: #ffffff;
  text-decoration: none; }
  a:active, a:focus, a:hover {
    color: #ffffff; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

figure {
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  text-transform: uppercase; }

button {
  display: inline-block;
  background: transparent;
  color: #ffffff;
  border: 0;
  outline: 0;
  font-size: 1.25rem;
  font-weight: 100;
  padding: 10px;
  cursor: pointer; }

figure {
  text-align: center; }
  figure img {
    width: 100%; }

p {
  margin: 0; }

html {
  min-width: 320px;
  font-size: 16px;
  background: #000000;
  color: #ffffff; }
  @media screen and (min-width: 768px) {
    html {
      font-size: 20px; } }

.main-container {
  overflow: hidden;
  display: flex;
  flex: 1 1 100%;
  flex-flow: column nowrap;
  max-width: 1080px;
  padding-bottom: 80px; }

h2 {
  font-size: 1.4rem; }

.brand-preview {
  min-height: calc(95vh - 80px);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  transform-origin: center center;
  padding: 20px 0;
  overflow: hidden; }

.brand-preview__headline {
  position: relative;
  flex: 0 0 100%;
  font-size: 3rem;
  padding: 10px 0;
  opacity: 0;
  transform: translateY(25vh);
  transition: all 0.4s;
  transition-timing-function: ease-out;
  text-align: center;
  cursor: pointer;
  font-size: 7.5vw; }
  @media screen and (min-width: 1080px) {
    .brand-preview__headline {
      font-size: 4rem; } }
  .brand-preview--active .brand-preview__headline {
    opacity: 1;
    transform: translateY(0); }

.brand-preview__visuals {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 100%;
  justify-content: center; }
  @media screen and (min-width: 900px) {
    .brand-preview__visuals {
      padding: 0 20px; } }

.brand-preview__image-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 50%;
  opacity: 0;
  transform: translateY(25vh);
  transition-timing-function: ease-out;
  transition: all 0.4s;
  padding: 0 10px 80px; }
  .brand-preview--active .brand-preview__image-wrapper {
    opacity: 1;
    transform: translateY(0); }

.brand-preview__image {
  max-width: 475px; }
  @media screen and (min-width: 900px) {
    .brand-preview__image {
      max-width: 450px; } }

.brand-preview--active .brand-preview__visuals--style-01 .brand-preview__image-wrapper-01 {
  transition: all 0.4s 0.3s; }

.brand-preview--active .brand-preview__visuals--style-01 .brand-preview__image-wrapper-02 {
  transition: all 0.4s 0.4s; }

.brand-preview--active .brand-preview__visuals--style-02 .brand-preview__image-wrapper-01 {
  transition: all 0.4s 0.2s; }

.brand-preview--active .brand-preview__visuals--style-02 .brand-preview__image-wrapper-02 {
  transition: all 0.4s 0.3s; }

.brand-preview--active .brand-preview__visuals--style-03 .brand-preview__image-wrapper-01 {
  transition: all 0.4s 0.3s; }

.brand-preview--active .brand-preview__visuals--style-03 .brand-preview__image-wrapper-02 {
  transition: all 0.4s 0.2s; }

.brand-preview--active .brand-preview__visuals--style-04 .brand-preview__image-wrapper-01 {
  transition: all 0.4s 0.2s; }

.brand-preview--active .brand-preview__visuals--style-04 .brand-preview__image-wrapper-02 {
  transition: all 0.4s 0.3s; }

.brand-page {
  padding-bottom: 80px; }

.brand-page-header__container {
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center; }

.brand-page-header__main-headline {
  position: absolute;
  text-align: center;
  max-width: calc(100% - 20px);
  font-size: 8vw; }
  @media screen and (min-width: 768px) {
    .brand-page-header__main-headline {
      max-width: 80%; } }
  @media screen and (min-width: 1080px) {
    .brand-page-header__main-headline {
      font-size: 4rem; } }

.brand-page-header__image-wrapper {
  height: 100%; }

.brand-page-header__image {
  height: 100%;
  width: auto; }

.brand-page-main-container {
  max-width: 1080px;
  margin: 0 auto; }

.brand-page-information {
  display: flex;
  flex-flow: row wrap; }
  @media screen and (min-width: 768px) {
    .brand-page-information {
      flex-flow: row nowrap;
      border: 1px solid #555555;
      margin: 80px 0; } }

.brand-page-information__block-01 {
  flex: 1 1 100%; }
  @media screen and (min-width: 768px) {
    .brand-page-information__block-01 {
      flex: 1 1 0; } }

.brand-page-information__block-02 {
  flex: 1 1 100%; }
  @media screen and (min-width: 768px) {
    .brand-page-information__block-02 {
      flex: 3 1 0; } }

.brand-page-information__field {
  display: flex;
  padding: 20px; }

.brand-page-information__field-short {
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #555555; }
  @media screen and (min-width: 768px) {
    .brand-page-information__field-short {
      border-right: 1px solid #555555; } }

.brand-page-information__field-long {
  flex-flow: column wrap;
  align-items: baseline;
  justify-content: space-between;
  min-height: 100%; }

@media screen and (min-width: 768px) {
  .brand-page-information__text-field {
    flex: 1 1 100%; } }

.brand-page-information__text-field-01 {
  text-transform: uppercase; }

.brand-page-information__text-field-02 {
  font-size: 0.8rem;
  font-weight: 100; }

.brand-page-information__text-field-social {
  display: flex;
  flex-flow: row wrap; }

.brand-page-information__link {
  display: flex; }

.brand-page-information__button-more {
  padding: 20px 0 0 0;
  font-size: 0.8rem;
  font-weight: 100;
  text-transform: uppercase; }

.brand-page-images {
  display: flex;
  flex-flow: row wrap;
  padding: 60px 20px; }

.brand-page-images__side {
  flex: 1 1 50%; }

.brand-page-images__side-left {
  margin-top: 60px; }

.brand-page-images__image-wrapper {
  padding: 20px; }

.brand-page-images__image {
  flex: 1 1 50%; }

.sidebar {
  position: fixed;
  top: 50%;
  right: 0px;
  transform: translateX(100%);
  transition: transform 0.4s; }

.sidebar--active {
  transform: translateX(0); }

.sidebar__list {
  transform: translateY(-50%); }

.sidebar__element {
  position: relative;
  border: 1px solid transparent; }
  .sidebar__element::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0px;
    height: 0px;
    border: 1px solid #ffffff;
    pointer-events: none;
    width: 50px;
    opacity: .66;
    transform: translateX(50%);
    transition: all 0.4s; }
  .sidebar__element:hover .sidebar__link {
    transform: translateX(0%);
    opacity: 1;
    transition: transform 0.4s, opacity 0.26667s 0.13333s; }
  .sidebar__element:hover::after {
    opacity: .8;
    transform: translateX(25%); }

.sidebar__element--active::after {
  transform: translateX(25%); }

.sidebar__link {
  display: block;
  padding: 5px 50px 5px 0;
  transform: translateX(50%);
  opacity: 0;
  text-shadow: 1px 1px 2px #000000;
  transition: transform 0.4s, opacity 0.2s; }

.progress-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: #ddff00; }

/*** OVERLAY / SUBMENU ***/
.submenu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  transform: scale(0);
  transform-origin: top right;
  background: rgba(0, 0, 0, 0);
  transition: all 0.4s; }

.submenu__content-wrapper {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-top: 80px; }

.submenu__list {
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  max-height: 100%;
  overflow: scroll; }
  @media screen and (min-width: 768px) {
    .submenu__list {
      padding: 0 33%; } }

.submenu--active {
  background: black;
  transform: scale(1); }

.submenu__link {
  display: inline-block;
  padding: 10px 0;
  color: #999999;
  transition: color 0.4s; }
  .submenu__link:hover {
    color: #ffffff; }

.submenu__viewer-wrapper {
  display: none;
  position: absolute;
  top: 0;
  height: 100%;
  width: 33%; }
  @media screen and (min-width: 768px) {
    .submenu__viewer-wrapper {
      display: block; } }

.submenu__viewer-wrapper-01 {
  left: 0; }

.submenu__viewer-wrapper-02 {
  right: 0; }

.submenu__viewer-01 {
  top: 40px; }

.submenu__viewer-02 {
  top: 50%;
  transform: translateY(-50%); }

.submenu__viewer-03 {
  bottom: 40px; }

.submenu__viewer {
  position: absolute;
  left: 0;
  width: 100%;
  filter: blur(3px);
  transition: all 0.4s; }
  .submenu__viewer.submenu__viewer--active {
    filter: blur(0); }

.submenu__viewer-image {
  max-width: 250px;
  display: none; }
  .submenu__viewer--active .submenu__viewer-image {
    display: inline-block; }

.submenu__float-background {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  @media screen and (min-width: 768px) {
    .submenu--active .submenu__float-background {
      display: block; } }

.submenu__float-image-wrapper {
  position: absolute;
  top: 0; }

.submenu__float-image-wrapper-00 {
  width: 15%; }
  .submenu__float-image-wrapper-00 .submenu__float-image {
    opacity: .25;
    filter: blur(5px); }

.submenu__float-image-wrapper-01 {
  width: 18%; }
  .submenu__float-image-wrapper-01 .submenu__float-image {
    opacity: .30;
    filter: blur(3px); }

.submenu__float-image-wrapper-02 {
  width: 20%; }
  .submenu__float-image-wrapper-02 .submenu__float-image {
    opacity: .40;
    filter: blur(4px); }

.submenu__headline {
  position: absolute;
  top: 0;
  left: 0;
  flex: 1 1 100%;
  width: 100%;
  height: 80px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center; }

.button-close-overlay {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px; }

/*** MENU ***/
.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 10px; }

.menu__list {
  display: flex;
  flex-flow: row wrap;
  flex: 0 0 100%;
  align-items: center; }

.menu__element {
  flex: 0 0 33.33%; }

.menu__link {
  font-size: .9rem; }

.header-container {
  max-width: 1080px;
  margin: 0 auto;
  overflow: hidden; }

.header-content {
  padding-top: 80px;
  position: relative;
  padding-bottom: 80px;
  min-height: 100vh;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  -webkit-perspective: 750px;
  perspective: 750px; }
  .header-content.header-content--active::before {
    transform: translateY(0vh) rotate(45deg);
    transition: transform 0.8s 2s;
    transition-timing-function: cubic-bezier(0.5, 0.01, 0.54, 1.25); }
  .header-content.header-content--active::after {
    transform: translateY(0vh);
    height: 25%;
    transition: transform 0.8s 2s, height 1.2s 2s;
    transition-timing-function: cubic-bezier(0.5, 0.01, 0.54, 1.25); }
  .header-content::before {
    content: '';
    position: absolute;
    bottom: 21px;
    left: 50%;
    width: 9px;
    height: 9px;
    border-right: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    transform: translateY(-125vh) rotate(45deg);
    transform-origin: 1px 0px; }
  .header-content::after {
    content: '';
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 1px;
    height: 7.5%;
    background: #ffffff;
    transform: translateY(-125vh); }

.header__main-headline {
  position: absolute;
  letter-spacing: -.5rem;
  text-align: center;
  font-size: 10vw;
  transform: rotateX(90deg); }
  @media screen and (min-width: 1080px) {
    .header__main-headline {
      font-size: 5rem; } }
  .header-content--active .header__main-headline {
    transform: rotateX(0);
    transition: color 0.4s, transform 0.8s 1.2s; }

.header__visuals {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center; }

.header__image-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0 50%; }

.header__image {
  position: relative; }
  @media screen and (min-width: 900px) {
    .header__image {
      max-width: 440px; } }

.header__image-wrapper-01 {
  justify-content: flex-end; }
  .header__image-wrapper-01 .header__image {
    right: -18%;
    transform: translate(-80%, -33%) scale(0.75); }
    .header-content--active .header__image-wrapper-01 .header__image {
      transform: translate(0%, 0%);
      transition: transform 0.8s;
      transition-timing-function: cubic-bezier(0.5, 0.01, 0.54, 1.25); }

.header__image-wrapper-02 {
  justify-content: flex-start; }
  .header__image-wrapper-02 .header__image {
    left: -18%;
    transform: translate(80%, -33%) scale(0.75); }
    .header-content--active .header__image-wrapper-02 .header__image {
      transform: translate(0%, 0%);
      transition: transform 0.8s;
      transition-timing-function: cubic-bezier(0.5, 0.01, 0.54, 1.25); }

.header__spinner-wrapper {
  position: absolute;
  bottom: -8vw;
  right: calc(-8vw + 9%);
  transform: scale(0); }
  @media screen and (min-width: 1080px) {
    .header__spinner-wrapper {
      bottom: -90px;
      right: calc(-90px + 9%); } }
  .header-content--active .header__spinner-wrapper {
    transform: scale(1);
    transition: 0.8s 1.4s;
    transition-timing-function: cubic-bezier(0.5, 0.01, 0.54, 1.25); }

.header__spinner {
  display: block;
  width: 16vw; }
  @media screen and (min-width: 1080px) {
    .header__spinner {
      max-width: 180px; } }
